<template>
  <div class="content">
    <!-- 内容 -->
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p v-html="item.text"></p>
          <img :src="item.imgSrc" alt="" class="img1" />
        </div>
      </div>
    </template>
    <!-- 内容 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          imgSrc: require("@/assets/img/health-case1.png"),
          title: "国肽生物防伪溯源及防窜货系统",
          text: "人民国肽集团是一家年产值超百亿的多元化业务的大健康综合管理集团。荣获“健康中国十大突出贡献企业”、“最具健康价值品牌”等多项荣誉。",
        },
        {
          imgSrc: require("@/assets/img/洋河追溯体系.jpg"),
          title: "洋河股份酒类追溯体系",
          text: "秀圆果原班团队从2012年起为洋河股份建设RFID防伪溯源系统，在高端产品“梦之蓝”系列酒采用了基于单瓶酒的“RFID电子标签”实现防伪溯源，每年投入使用RFID标签近2000万张；在中端产品“海天”系列酒采用了基于单瓶酒的“追溯码”实现溯源；在其它低端产品采用“批次码”实现溯源。使用不同技术应用不同类型产品，为洋河股份全面构建酒类追溯体系。<br>洋河股份RFID防伪溯源项目获得2015年中国RFID行业十大最有影响力成功应用。",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  // 偶数列背景颜色
  .contentArr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
@media screen and (max-width: 750px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 100%;
      overflow: hidden;
      .contentText {
        padding-bottom: 2.8rem;
        width: 15.87rem;
        margin: auto;
        margin-top: 1.6rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          margin-top: 0.1rem;
        }
        p {
          // text-indent: 0.48rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #505050;
          line-height: 1.02rem;
        }
        .img1 {
          width: 15.87rem;
          height: 10.09rem;
          margin-top: 0.46rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  // 内容样式
  .contentArr {
    background: #fff;
    width: 19.2rem;
    overflow: hidden;
    .contentText {
      width: 100%;
      float: left;
      position: relative;
      padding-bottom: 2.8rem;
      margin: 1.6rem 0 0 1.69rem;
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
      h3 {
        height: 0.84rem;
        font-size: 0.5rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #093c7f;
        line-height: 0.7rem;
        margin-top: 0.05rem;
      }
      p {
        // text-indent: 0.48rem;
        margin-top: 0.2rem;
        width: 8.71rem;
        font-size: 0.2rem;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #000000;
        line-height: 0.36rem;
      }
      .img1 {
        width: 6.78rem;
        height: 4.32rem;
        position: absolute;
        top: 0rem;
        left: 9rem;
      }
      .care {
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.21rem;
        margin-top: 0.2rem;
        .careContent {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
</style>
